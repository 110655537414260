export default {
  name: 'YearMonthSelect',
  props: {
    yearModel: {
      type: String,
      default: ''
    },
    monthModel: {
      type: String,
      default: ''
    },
    yearsList: {
      type: Array,
      default: () => []
    },
    monthsList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    selectYear: {
      get() {
        return this.yearModel;
      },
      set(val) {
        this.$emit('update:yearModel', val);
      }
    },
    selectMonth: {
      get() {
        return this.monthModel;
      },
      set(val) {
        this.$emit('update:monthModel', val);
      }
    }
  }
};