var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("li", {
    staticClass: "payslip-item",
    on: {
      click: _vm.goDetail
    }
  }, [_c("p", {
    staticClass: "time"
  }, [_vm._v(_vm._s(_vm.detailData.sendTime))]), _c("div", {
    staticClass: "payslip-tit"
  }, [_c("h3", [_vm._v(_vm._s(_vm.detailData.title))]), _c("span", [_vm._v(_vm._s(_vm.detailData.paidWages))])]), _vm._m(0)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "info"
  }, [_c("h3", [_vm._v("查看")]), _c("span", [_vm._v("实发金额")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };