import "core-js/modules/es.array.push.js";
import { formatChinaTime } from '@/utils/util';
export default {
  name: 'ListItem',
  props: {
    salary: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      detailData: {}
    };
  },
  mounted() {
    this.handleDealData(this.salary);
  },
  methods: {
    handleDealData(data) {
      data.sendTime = formatChinaTime(data.sendTime, 'yyyy-MM-dd HH:mm:ss');
      const {
        year,
        month
      } = data;
      data.title = `${year}年${month}月工资条`;
      this.detailData = data;
    },
    goDetail() {
      const {
        year,
        month,
        uploadRecordId
      } = this.salary;
      this.$router.push({
        path: '/payslip-detail',
        query: {
          year,
          month,
          uploadRecordId
        }
      });
    }
  }
};