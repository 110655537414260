import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import { debounce, getCurrentMonth, getCurrentYear } from '@/utils/util';
import CommonHeader from '@/components/common/CommonHeader';
import NoData from '@/components/common/NoData';
import YearMonthSelect from '@/components/payslipList/YearMonthSelect';
import ListItem from '@/components/payslipList/ListItem';
import { salaryDetailGetSalaryListByUserId } from '@/api/salary';
const currentYear = getCurrentYear().toString(); // 当前年份
const currentMonth = getCurrentMonth().toString(); // 当前月份
export default {
  name: 'PayslipListChild',
  components: {
    CommonHeader,
    NoData,
    YearMonthSelect,
    ListItem
  },
  data() {
    return {
      yearModel: '',
      monthModel: '',
      yearsList: [],
      monthsList: [],
      salaryLists: [],
      noMore: false,
      params: {}
    };
  },
  computed: {
    ...mapGetters({
      salaryListQuery: 'payslip/salaryListQuery'
    })
  },
  watch: {
    yearModel() {
      this.changeYearFn();
      if (this.yearModel === currentYear && Number(this.monthModel) > Number(currentMonth)) {
        // 避免年份变化时，修改月份再次触发事件
      } else {
        this.dateChangeFn({
          year: this.yearModel
        });
      }
    },
    monthModel(val) {
      this.dateChangeFn({
        year: this.yearModel,
        month: val
      });
    }
  },
  methods: {
    // 初始化年份数据
    initYearFn() {
      if (!this.yearModel) {
        this.yearModel = currentYear;
      }
      this.handleYearsList();
    },
    // 处理年份列表
    handleYearsList() {
      const year = currentYear > this.yearModel ? currentYear : this.yearModel;
      const yearsList = [];
      for (let i = 0; i < 3; i++) {
        yearsList.push({
          label: `${year - i}年`,
          value: (year - i).toString()
        });
      }
      this.yearsList = yearsList;
    },
    // 处理月份列表
    handleMonthsList(maxMonth) {
      const monthsList = [];
      for (let i = 1; i <= maxMonth; i++) {
        monthsList.push({
          label: `${i}月`,
          value: i.toString()
        });
      }
      this.monthsList = monthsList;
    },
    // 切换年份
    changeYearFn() {
      let maxMonth = 12;
      if (this.yearModel === currentYear) {
        // 今年  月份只能选择当前月
        maxMonth = currentMonth;
        if (Number(this.monthModel) > Number(currentMonth)) {
          this.monthModel = currentMonth;
        }
      }
      this.handleMonthsList(maxMonth);
    },
    // 重设年月筛选
    recoverYearMonthFn() {
      const {
        year,
        month
      } = this.salaryListQuery;
      this.yearModel = year;
      this.initYearFn();
      this.monthModel = month;
    },
    // 请求工资条列表数据
    async fetchListData() {
      const res = await salaryDetailGetSalaryListByUserId(this.params);
      this.salaryLists = (res === null || res === void 0 ? void 0 : res.data) || [];
      // 没有数据了
      this.noMore = true;
    },
    dateChangeFn(date) {
      this.onFetchData(date);
    },
    onFetchData: debounce(function (date) {
      this.params = {
        ...this.params,
        year: date.year
      };
      if (date.month) {
        this.params = {
          ...this.params,
          month: date.month
        };
      } else if (date.month === '') {
        delete this.params.month;
      }
      this.salaryLists = [];
      this.fetchListData();
    }, 500)
  }
};